import React from 'react'
import { Layout } from '../../components/AppLayout'
import { RDHelmet } from '../../components/RDHelmet'
import { AppHomeBookDemo } from '../../components/AppHomeBookDemo'
import { AppSpendMattersSupplierManagementBanner } from '../../components/AppSpendMattersSupplierManagementBanner'
import { AppSpendMattersSupplierManagementGraphicSection } from '../../components/AppSpendMattersSupplierManagementGraphicSection'

const SpendMattersSupplierManagement = () => {
  const schemaMarkup = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Raindrop Systems Inc',
    alternateName: 'Raindrop Systems Inc',
    url: 'https://raindrop.com/',
    logo: 'https://storage.googleapis.com/raindroppublic/website_data/raindrop_icon.svg',
    description:
      'Raindrop is the modern AI-Powered Source-to-Pay SaaS Enterprise Spend Management Full-Suite Modular Platform. Raindrop offers an AI-enabled enterprise cloud based spend management software focused on cost savings. Get started with free tools, and upgrade as you grow.',
    image: 'https://storage.googleapis.com/raindroppublic/website_data/OurServices.jpg',
    email: 'info@raindrop.com',
    contactPoint: [
      { '@type': 'ContactPoint', telephone: '408-352-5162', contactType: 'Business', areaServed: 'United States' },
    ],
    address: {
      '@type': 'Corporate',
      addressLocality: ' 226 Airport Parkway Suite 250',
      addressRegion: ' San Jose, California 95110',
      postalCode: '95110',
      addressCountry: 'United States',
    },
    sameAs: [
      'https://www.facebook.com/Raindrop-Systems-Inc-103810868375793',
      'https://twitter.com/saas_raindrop',
      'https://www.instagram.com/raindrop_spendmanagement/?igshid=1mn6sbd70aybs',
      'https://www.linkedin.com/company/raindropsystemsinc/',
    ],
  }
  return (
    <Layout>
      <RDHelmet
        subtitle={'SpendMattersSupplierManagement'}
        contentDescription="AI-powered Supplier Management leader per Spend Matters 2025 - #1 in customer scores for deployment speed, TCO & UX. Transform supplier relationships with end-to-end visibility & risk control"
        contentKeywords="content='Spend Matters Supplier Management 2025, SXM software leader, AI supplier risk management, Raindrop SXM SolutionMap, supplier lifecycle automation, procurement compliance tools, supplier relationship management, S2P integration platform, supplier performance analytics, procurement ROI tools'"
        contentOG="AI Powered Source-to-Pay SaaS Platform"
        contentOgDescription="AI-powered Supplier Management leader per Spend Matters 2025 - #1 in customer scores for deployment speed, TCO & UX. Transform supplier relationships with end-to-end visibility & risk control"
        contentOgUrl="https://raindrop.com/resources/SpendMattersSupplierManagement"
        schemaMarkup={schemaMarkup}
      />
      <AppSpendMattersSupplierManagementBanner />
      <AppSpendMattersSupplierManagementGraphicSection />
      <AppHomeBookDemo />
    </Layout>
  )
}

export default SpendMattersSupplierManagement
